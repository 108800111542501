import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentService } from 'src/app/services/environment.service';


@Component({
  selector: 'app-dataset-preview',
  templateUrl: './dataset-preview.component.html',
  styleUrls: ['./dataset-preview.component.scss'],
})
export class DatasetPreviewComponent {
  @Input() showToolbar: boolean;
  @Input() showButtonGroup: boolean = true;
  @Input() datasetLimit: number;

  constructor(
    private router: Router,
    private env: EnvironmentService,
  ) { }

  async ourSourcesClickHandler() {
    await this.router.navigate(['about']);

    setTimeout(() => {
      document
        .querySelector('#partners')
        .scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }

  get numRecords(): string {
    return Number(this.env.texts.numRecords).toLocaleString();
  }

  get numDatasets(): number {
    return this.env.texts.numDatasets;
  }

  get numSources(): number {
    return this.env.texts.numSources;
  }

}
