import { Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DatasetFilterService } from './services/dataset-filter.service';
import { DomEventsUtilsService } from './services/dom-events-utils.service';
import { EnvironmentService } from './services/environment.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';

const scrollToTopPaths = ['/', 'datasets', 'about', 'services'];
const meta = {
  'og:description':
    'Making the Cultural Heritage of the Jewish People globally visible',
  'og:url': 'https://jhn.ngo',
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private domEvents: DomEventsUtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private filterService: DatasetFilterService,
    private env: EnvironmentService,
    private title: Title,
    private meta: Meta,
    private analytics: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    let datasetLanguageValue: string;
    let datasetSourceValue: string;
    let searchValue: string;
    let currentPath: string;

    const navEndsEvents = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    navEndsEvents.subscribe((event: NavigationEnd) => {
      if (this.env.gAnaliticsId) {
        this.analytics.send(event.url);
      }

      // Setting filter service values from url
      const {
        datasetLanguage,
        datasetSource,
        search,
        contentType,
      } = this.route.snapshot.queryParams;

      const decodedDatasetLanguage = datasetLanguage
        ? decodeURIComponent(datasetLanguage)
        : '';
      if (decodedDatasetLanguage !== datasetLanguageValue) {
        datasetLanguageValue = decodedDatasetLanguage;
        this.filterService.datasetLanguage$.next(datasetLanguageValue);
      }

      const decodedDatasetSource = datasetSource
        ? decodeURIComponent(datasetSource)
        : '';
      if (decodedDatasetSource !== datasetSourceValue) {
        datasetSourceValue = decodedDatasetSource;
        this.filterService.datasetSource$.next(datasetSourceValue);
      }

      const decodedSearchValue = search ? decodeURIComponent(search) : '';
      if (decodedSearchValue !== searchValue) {
        searchValue = decodedSearchValue;
        this.filterService.searchValue$.next(searchValue);
      }

      let contentTypes: any[];
      switch (true) {
        case Array.isArray(contentType) && contentType.length > 0:
          contentTypes = contentType.map(
            (val: string) => val && decodeURIComponent(val)
          );
          break;
        case typeof contentType === 'string' && contentType.length > 0:
          contentTypes = [decodeURIComponent(contentType)];
          break;
      }
      contentTypes &&
        contentTypes.length &&
        this.filterService.selectedContentTypes$.next(new Set(contentTypes));

      // We need to scroll up on some routes
      const fullPath = this.router.parseUrl(this.router.url).toString();

      if (currentPath != fullPath) {
        currentPath = fullPath;
        if (scrollToTopPaths.find((p) => fullPath.startsWith(p))) {
          setTimeout(() => {
            window.scroll(0, 0);
          }, 0);
        }
      }

      this.updateMetaAndTitle(fullPath);
    });
  }

  updateMetaAndTitle(fullPath: string): void {
    const path = fullPath.split('/')[1];

    const title = `${path === '' ? 'Home' : path.charAt(0).toUpperCase() + path.slice(1)
      } | ${this.env.texts.title}`;

    this.title.setTitle(title);

    this.meta.updateTag({
      name: 'og:title',
      content: title,
    });

    Object.keys(meta).forEach((name) =>
      this.meta.updateTag({
        name,
        content: meta[name],
      })
    );
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: Event) {
    this.domEvents.clickedTarget.next(event.target);
  }
}
