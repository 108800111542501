<button
  class="hamburger hamburger--collapse"
  [ngClass]="{ 'is-active': isActive }"
  type="button"
  (click)="clickHandler()"
>
  <span class="hamburger-box">
    <span class="hamburger-inner"></span>
  </span>
</button>
