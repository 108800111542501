import { Component, Input } from '@angular/core';
import Partner from 'src/app/model/Partner';

@Component({
  selector: 'app-partner-card',
  templateUrl: './partner-card.component.html',
  styleUrls: ['./partner-card.component.scss'],
})
export class PartnerCardComponent {
  @Input() partner: Partner;
}
