<div class="input">
  <div class="input-icon input-icon_before"></div>
  <input
    #input
    [type]="type"
    [placeholder]="placeholder"
    [value]="value"
    (keyup)="onChange.emit(input.value)"
  />
  <div class="input-icon input-icon_after"></div>
</div>
