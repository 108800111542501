<div class="team-member-card">
  <div class="team-member-card__photo">
    <img [src]="teamMember.photoUrl" [alt]="teamMember.name" />
  </div>
  <div class="team-member-card__info">
    <div class="team-member-card__name">
      {{ teamMember.name }}
    </div>
    <div class="team-member-card__position">
      {{ teamMember.position }}
    </div>
  </div>
  <div class="team-member-card__links">
    <a
      *ngFor="let link of teamMember.links"
      [href]="link.url"
      [ngClass]="[
        'team-member-card__link-icon',
        'team-member-card__link-icon_' + link.origin
      ]"
    ></a>
  </div>
</div>
