import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss'],
})
export class HamburgerComponent {
  @Input() isActive: boolean;
  @Output() onToggleMenu: EventEmitter<void> = new EventEmitter();

  clickHandler(): void {
    this.onToggleMenu.emit();
  }
}
