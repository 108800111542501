<div class="item-card">
  <div
    class="item-card-image"
    [ngClass]="{ 'item-card-image_round': item.roundImage }"
    [ngStyle]="{ color: shadowColor }"
  >
    <img [src]="item.imageUrl" [alt]="item.title" />
  </div>
  <div class="item-card-info">
    <div class="item-card-info__title">
      {{ item.title }}
    </div>
    <div class="item-card-info__description">
      {{ item.description }}
    </div>
    <a
      [ngStyle]="{ visibility: item.link ? 'visible' : 'hidden' }"
      [href]="isAbsoluteLink(item.link) && item.link"
      [routerLink]="!isAbsoluteLink(item.link) && item.link"
      target="_blank"
      rel="noopener noreferrer"
    >
      <app-button preset="transparent-blue" [link]="item.link"
        >Learn More</app-button
      >
    </a>
  </div>
</div>
