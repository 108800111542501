<div class="partners__partner-card">
  <a
    [href]="partner.url"
    target="_blank"
    rel="noopener noreferrer"
    [title]="partner.name"
  >
    <img
      class="partners__partner-card_img"
      [src]="partner.logo"
      [alt]="partner.name"
    />
  </a>
</div>
