import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import Environment, { AppTexts } from 'src/environments/environment.interface';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements Environment {
  get production(): boolean {
    return env.production;
  }

  get gAnaliticsId(): string {
    return env.gAnaliticsId;
  }

  get texts(): AppTexts {
    return env.texts;
  }

  get datasetsFetchUrl(): string {
    return env.datasetsFetchUrl;
  }

  get partnersFetchUrl(): string {
    return env.partnersFetchUrl;
  }

  get servicesFetchUrl(): string {
    return env.servicesFetchUrl;
  }

  get newsFetchUrl(): string {
    return env.newsFetchUrl;
  }

  get supportersFetchUrl(): string {
    return env.supportersFetchUrl;
  }

  get teamMembersFetchUrl(): string {
    return env.teamMembersFetchUrl;
  }

  get partnersAboutFetchUrl(): string {
    return env.partnersAboutFetchUrl;
  }

  get datasetDetailsFetchUrl(): string {
    return env.datasetDetailsFetchUrl;
  }

  get vacanciesFetchUrl(): string {
    return env.vacanciesFetchUrl;
  }

  get projectsFetchUrl(): string {
    return env.projectsFetchUrl;
  }
}
