<a
  *ngIf="link && !isAbsoluteLink"
  [routerLink]="link"
  [ngClass]="['btn btn_' + preset]"
>
  <ng-container *ngTemplateOutlet="content"> </ng-container>
</a>

<a
  *ngIf="link && isAbsoluteLink"
  [href]="link"
  target="_blank"
  rel="noopener noreferrer"
  [ngClass]="['btn btn_' + preset]"
>
  <ng-container *ngTemplateOutlet="content"> </ng-container>
</a>

<button
  (click)="onClick.emit()"
  *ngIf="!link"
  [ngClass]="['btn btn_' + preset]"
>
  <ng-container *ngTemplateOutlet="content"> </ng-container>
</button>

<ng-template #content>
  <div [ngClass]="['btn__icon btn__icon_' + preset]"></div>
  <div [ngClass]="['btn__title btn__title_' + preset]">
    <ng-content></ng-content>
  </div>
</ng-template>
