import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import NewsItem from 'src/app/model/NewsItem';
import { NewsStore } from 'src/app/services/news.service';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
})
export class NewsListComponent implements OnInit {
  news: Observable<NewsItem[]>;

  constructor(private store: NewsStore) {}

  ngOnInit(): void {
    this.news = this.store.getAll();
  }
}
