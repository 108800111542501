import { Component, ElementRef, Input } from '@angular/core';
import {
  ContentType,
  ContentTypeDetails,
  getContentTypeDetails,
} from 'src/app/model/ContentType';
import Dataset from 'src/app/model/Dataset';
import Partner from 'src/app/model/Partner';
import { PartnerAboutStore } from 'src/app/services/partner-about.service';

@Component({
  selector: 'app-dataset-preview-card',
  templateUrl: './dataset-preview-card.component.html',
  styleUrls: ['./dataset-preview-card.component.scss'],
})
export class DatasetPreviewCardComponent {
  @Input() dataset: Dataset;

  constructor(public el: ElementRef, private partnerStore: PartnerAboutStore) {}

  encodeURIComponent(uri: string) {
    return encodeURIComponent(uri);
  }

  getContentTypeDetails(contentType: ContentType): ContentTypeDetails {
    return getContentTypeDetails(contentType);
  }

  get partners() {
    return this.partnerStore.getAll();
  }

  getCurrentProviderName(partners: Partner[]): string {
    const partner: Partner = partners
      .filter((p: Partner) => p.id === this.dataset.providerId)
      .pop();

    return partner && partner.name;
  }
}
