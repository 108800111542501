<main class="home">
  <app-header [headerTitle]="headerTitle"></app-header>
  <app-dataset-preview
    [showToolbar]="false"
    [datasetLimit]="DATASET_LIMIT"
  ></app-dataset-preview>
  <app-partners [store]="partnerStore"></app-partners>
  <app-services #services></app-services>
  <app-news></app-news>
  <app-footer></app-footer>
</main>
