<div
  class="dataset-preview-list-container"
  *ngIf="datasets | async as datasets"
  #datasetPreviewListContainer
>
  <app-dataset-preview-toolbar
    *ngIf="showToolbar"
    [languageItems]="languages"
    [datasetSourcesItems]="institutions"
  ></app-dataset-preview-toolbar>
  <button
    *ngIf="!showToolbar"
    (click)="navIconClickHandler(false)"
    class="dataset-preview-list-nav dataset-preview-list-nav_left"
  >
    <img src="/assets/img/icons/nav-left.svg" alt="Previous dataset" />
  </button>
  <div
    class="dataset-preview-list"
    [ngClass]="{ 'dataset-preview-list_vertical': showToolbar }"
    #datasetPreviewList
  >
    <app-dataset-preview-card
      *ngFor="let dataset of datasets.slice(0, limit) | datasetFilter"
      [dataset]="dataset"
    ></app-dataset-preview-card>
  </div>
  <button
    *ngIf="!showToolbar"
    (click)="navIconClickHandler(true)"
    class="dataset-preview-list-nav dataset-preview-list-nav_right"
  >
    <img src="/assets/img/icons/nav-right.svg" alt="Next dataset" />
  </button>
</div>
