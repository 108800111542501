<app-hamburger
  [isActive]="isOpen"
  (onToggleMenu)="toggleMenuHandler()"
></app-hamburger>
<div class="mobile-menu" [ngClass]="{ 'mobile-menu_active': isOpen }">
  <div
    class="mobile-menu-overlay-tint"
    [@tintToggle]="isOpen ? 'open' : 'closed'"
  ></div>
  <div
    class="mobile-menu__nav"
    [@menuToggle]="isOpen ? 'open' : 'closed'"
    (@menuToggle.done)="animationDone()"
    #menu
  >
    <a routerLink="/datasets" class="mobile-menu__nav-item">Datasets</a>
    <a
      class="mobile-menu__nav-item"
      (click)="menuItemClickHandler('services', '/')"
      >Services</a
    >
    <a routerLink="/about" class="mobile-menu__nav-item">About</a>
    <a class="mobile-menu__nav-item" (click)="menuItemClickHandler('footer')"
      >Contact</a
    >
    <a routerLink="/" class="mobile-menu__nav-item_twitter"></a>
  </div>
</div>
