import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatasetFilterService } from 'src/app/services/dataset-filter.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { PartnerStore } from 'src/app/services/partner.service';
import { ServicesComponent } from './services/services.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild(ServicesComponent, { static: true })
  servicesComponent: ServicesComponent;

  DATASET_LIMIT = 8;

  constructor(
    public partnerStore: PartnerStore,
    private env: EnvironmentService,
    private filter: DatasetFilterService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.filter.reset();
    const { path } = this.activatedRoute.snapshot.routeConfig;

    if (path === 'services') {
      setTimeout(() => {
        this.servicesComponent.elementRef.nativeElement.scrollIntoView({
          behavior: 'smooth',
        });
      }, 1000);
    }
  }

  get headerTitle(): string {
    return this.env.texts.mainHeadline;
  }
}
