<main class="dataset" *ngIf="dataset">
  <app-header></app-header>
  <div class="component-content">
    <div class="dataset-details">
      <app-button preset="transparent-grey" (onClick)="goBackHandler()"
        >Back to Dataset Gallery</app-button
      >
      <img
        *ngIf="provider && provider.logo as previewImg"
        [src]="previewImg"
        [alt]="dataset.id"
        class="dataset-details__logo"
      />
      <div class="dataset-details__name">
        {{ dataset.name }}
      </div>
      <div class="dataset-details__source">
        {{ provider && provider.name }}
      </div>
      <div class="flex-container">
        <app-content-type-badge
          [contentType]="dataset.contentTypes && dataset.contentTypes[0]"
        ></app-content-type-badge>
        <div class="dataset-details__origin-and-language">
          <img src="/assets/img/icons/world.svg" alt="" />
          {{ provider && provider.location }},
          {{ dataset.languages.join(', ') }}
        </div>
        <div class="dataset-details__tags">
          <span *ngFor="let tag of dataset.tags; index as i">
            <a
              [routerLink]="['/', 'datasets']"
              [queryParams]="{ search: encodeURIComponent(tag) }"
              title="Search similar datasets"
              >{{ tag }}</a
            >{{ i + 1 < dataset.tags.length ? ', ' : '' }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="dataset-details__images component-content">
    <div
      class="dataset-details__image-wrapper"
      *ngFor="let imageUrl of dataset.previewImages"
    >
      <img class="dataset-details__image-item" [src]="imageUrl" alt="" />
    </div>
  </div>
  <div class="component-content">
    <div class="dataset-details">
      <div class="dataset-details__description">
        {{ dataset.description }}
      </div>
      <div class="dataset-details__links">
        <a
          *ngIf="dataset.original"
          [href]="dataset.original"
          class="dataset-details__links-item dataset-details__links-item_blue"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span></span>View original dataset on the
          {{ dataset.providerId }} website
        </a>
        <a
          *ngIf="dataset.europeana"
          [href]="dataset.europeana"
          class="dataset-details__links-item dataset-details__links-item_grey"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span></span>View this dataset on Europeana
        </a>
        <a
          *ngIf="dataset.dpla"
          [href]="dataset.dpla"
          class="dataset-details__links-item dataset-details__links-item_grey"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span></span>View this dataset on DP.LA
        </a>
      </div>
    </div>
  </div>
  <section class="dataset__developer-links">
    <div class="component-content">
      <div class="dataset__developer-links-wrap">
        <h3>
          <span>Are you a developer? Work with our data!</span> Access this
          dataset via our APIs:
        </h3>
        <div class="dataset__developer-links_type">
          <a [href]="dataset.oai" target="_blank" rel="noopener noreferrer"
            ><span>&lt;/&gt;</span> OAI</a
          >
        </div>
        <div class="dataset__developer-links_type">
          <a [href]="dataset.rest" target="_blank" rel="noopener noreferrer"
            ><span>{{ '{;}' }}</span> REST</a
          >
        </div>
        <div class="dataset__developer-links_type">
          <a href="#" [ngClass]="{ disabled: !dataset.iiif }"
            ><span><img src="assets/img/logos/iiif.png" alt="" /></span> IIIF</a
          >
        </div>
        <app-button preset="transparent-white" (onClick)="goBackHandler()"
          >Back to Dataset Gallery</app-button
        >
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</main>
