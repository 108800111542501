import { Component, Input } from '@angular/core';
import Partner from 'src/app/model/Partner';
import AbstractRestService from 'src/app/services/abstract-rest-service.interface';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
})
export class PartnersComponent {
  @Input() store: AbstractRestService<Partner>;
}
