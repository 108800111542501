<footer id="footer" class="footer">
  <div class="component-content">
    <div class="footer-first-row">
      <app-footer-navbar></app-footer-navbar>
      <app-footer-contacts></app-footer-contacts>
    </div>
    <div class="footer-second-row">
      <div class="footer-copyright">
        Jewish Heritage Network &copy; 2020. All Rights Reserved.
      </div>
      <app-footer-supporters></app-footer-supporters>
    </div>
  </div>
</footer>
