import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

export default abstract class AbstractRestService<T> {
  private subject = new BehaviorSubject<T[]>([]);

  constructor(
    protected http: HttpClient,
    protected url: string,
    protected fetchAllOnConstruct: boolean = true
  ) {
    if (fetchAllOnConstruct) {
      this.fetchAll().subscribe((response) => this.subject.next(response));
    }
  }

  getAll(): Observable<T[]> {
    return this.subject.asObservable();
  }

  fetchAll(): Observable<T[]> {
    return this.http.get<T[]>(this.url);
  }
}
