import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private env: EnvironmentService) {}

  send(page_path: string): void {
    gtag('config', this.env.gAnaliticsId, { page_path });
  }
}
