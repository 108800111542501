<div class="footer-supporters">
  <div class="footer-supporters__title">
    Supporters
  </div>
  <div class="footer-supporters__list">
    <div
      class="footer-supporters__item"
      *ngFor="let supporter of supporters | async"
    >
      <a
        [href]="supporter.url"
        target="_blank"
        rel="noopener noreferrer"
        [title]="supporter.title"
      >
        <img [src]="supporter.logoUrl" [alt]="supporter.title" />
      </a>
    </div>
  </div>
</div>
