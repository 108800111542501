<button
  class="category-badge"
  *ngIf="contentType && contentTypeDetails as details"
  [title]="details.description"
  (click)="onClick.emit(contentType)"
>
  <img
    *ngIf="details.imageUrl; else noIcon"
    class="category-badge__icon"
    [src]="details.imageUrl"
  />
  <ng-template #noIcon>
    <span
      class="category-badge__icon category-badge__icon_missing"
      [ngStyle]="{ backgroundColor: details.noIconColor }"
    ></span>
  </ng-template>
  <span
    *ngIf="showTitle"
    class="category-badge__description"
    [ngStyle]="{ color: details.fontColor }"
    >{{ details.description }}</span
  >
</button>
