<div class="dataset-preview-card" #datasetPreviewCard *ngIf="dataset">
  <a [routerLink]="['/datasets', dataset.id]" [title]="dataset.name">
    <div
      *ngIf="
        dataset.previewImages &&
        dataset.previewImages.length &&
        dataset.previewImages[0] as previewImg
      "
      class="dataset-preview-card__image"
    >
      <div class="box">
        <img [src]="previewImg" alt="" />
      </div>
      <app-content-type-badge
        [contentType]="dataset.contentTypes && dataset.contentTypes[0]"
        [showTitle]="false"
      ></app-content-type-badge>
      <div class="dataset-preview-card__image-on-hover-text">View Dataset</div>
    </div>
    <h4 class="dataset-preview-card__title">
      {{ dataset.name }}
    </h4>
  </a>
  <div
    class="dataset-preview-card__source"
    *ngIf="partners | async as partners"
  >
    {{ getCurrentProviderName(partners) }}
  </div>
  <div class="dataset-preview-card__tags">
    <span *ngFor="let tag of dataset.tags; index as i">
      <a
        [routerLink]="['/', 'datasets']"
        [queryParams]="{ search: encodeURIComponent(tag) }"
        queryParamsHandling="merge"
        title="Search similar datasets"
        >{{ tag }}</a
      >{{ i + 1 < dataset.tags.length ? ', ' : '' }}</span
    >
  </div>
</div>
