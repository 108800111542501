import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Supporter from '../model/Supporter';
import AbstractRestService from './abstract-rest-service.interface';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class SupporterStore extends AbstractRestService<Supporter> {
  constructor(http: HttpClient, env: EnvironmentService) {
    super(http, env.supportersFetchUrl);
  }
}
