import { Component, Input } from '@angular/core';
import Previewable from 'src/app/model/Previewable';

@Component({
  selector: 'app-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss'],
})
export class ItemCardComponent {
  @Input() item: Previewable;
  @Input() shadowColor: string;

  isAbsoluteLink(link: string): boolean {
    return link.indexOf('://') > 0 || link.indexOf('//') === 0;
  }
}
