import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ContentType, getContentTypeDetails } from 'src/app/model/ContentType';

@Component({
  selector: 'app-content-type-badge',
  templateUrl: './content-type-badge.component.html',
  styleUrls: ['./content-type-badge.component.scss'],
})
export class ContentTypeBadgeComponent {
  @Input() contentType: ContentType;
  @Input() showTitle: boolean = true;
  @Output() onClick = new EventEmitter<ContentType>();

  get contentTypeDetails() {
    return getContentTypeDetails(this.contentType);
  }
}
