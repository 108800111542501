import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment as env } from './environments/environment.dpla';

if (env.production) {
  enableProdMode();
}

if (env.gAnaliticsId) {
  const gaScript = document.createElement('script');
  gaScript.setAttribute('async', 'true');
  gaScript.setAttribute(
    'src',
    `https://www.googletagmanager.com/gtag/js?id=${env.gAnaliticsId}`
  );

  try {
    // Ublock Origin will block, have to be disabled for local tests
    document.documentElement.firstChild.appendChild(gaScript);

    const script = document.createElement('script');
    script.innerHTML = `<!-- Global site tag (gtag.js) - Google Analytics -->
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());`;

    document.head.appendChild(script);
  } catch (reason) {
    console.error(reason);
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
