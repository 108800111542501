import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import Partner from 'src/app/model/Partner';
import AbstractRestService from 'src/app/services/abstract-rest-service.interface';

@Component({
  selector: 'app-partners-list',
  templateUrl: './partners-list.component.html',
  styleUrls: ['./partners-list.component.scss'],
})
export class PartnersListComponent implements OnInit {
  @Input() store: AbstractRestService<Partner>;
  partners: Observable<Partner[]>;

  constructor() {}

  ngOnInit(): void {
    this.partners = this.store.getAll();
  }
}
