import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ContentType } from '../model/ContentType';

@Injectable({
  providedIn: 'root',
})
export class DatasetFilterService {
  datasetSource$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  datasetLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  searchValue$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  selectedContentTypes$: BehaviorSubject<
    Set<ContentType | string>
  > = new BehaviorSubject<Set<ContentType | string>>(
    new Set<ContentType | string>()
  );

  get datasetSource(): Observable<string> {
    return this.datasetSource$.asObservable();
  }

  get datasetLanguage(): Observable<string> {
    return this.datasetLanguage$.asObservable();
  }

  get searchValue(): Observable<string> {
    return this.searchValue$.asObservable();
  }

  get selectedContentTypes(): Observable<Set<ContentType | string>> {
    return this.selectedContentTypes$.asObservable();
  }

  reset(): void {
    setTimeout(() => this.datasetLanguage$.next(''), 0);
    setTimeout(() => this.searchValue$.next(''), 0);
    setTimeout(() => this.datasetSource$.next(''), 0);
    setTimeout(() => this.selectedContentTypes$.next(new Set()), 0);
  }

  get filterEnabled(): boolean {
    return (
      (this.datasetSource$.value ||
        this.datasetLanguage$.value ||
        this.searchValue$.value ||
        this.selectedContentTypes$.value.size) &&
      true
    );
  }
}
