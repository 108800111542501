import { Component, Input } from '@angular/core';
import Previewable from 'src/app/model/Previewable';

@Component({
  selector: 'app-preview-list',
  templateUrl: './preview-list.component.html',
  styleUrls: ['./preview-list.component.scss'],
})
export class PreviewListComponent {
  @Input() items: Previewable[];

  shadowColors: string[] = ['#0849FF', '#FAC807', '#545454'];

  getShadowColor(idx: number) {
    return this.shadowColors[
      (this.shadowColors.length + idx) % this.shadowColors.length
    ];
  }
}
