import { Component, ElementRef, ViewChild } from '@angular/core';
import { ServiceStore } from 'src/app/services/service.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent {
  @ViewChild('element') elementRef: ElementRef;

  constructor(private store: ServiceStore) {}

  get services() {
    return this.store.getAll();
  }
}
