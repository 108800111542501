import { Component, OnInit } from '@angular/core';
import Project from 'src/app/model/Project';
import { ProjectStore } from 'src/app/services/project.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { EnvironmentService } from 'src/app/services/environment.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit {
  project: Project;

  constructor(
    private route: ActivatedRoute,
    private store: ProjectStore,
    private router: Router,
    private meta: Meta,
    private title: Title,
    private env: EnvironmentService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.store.getAll().subscribe((data: Project[]) => {
        if (data && data.length) {
          this.project = data.filter((next) => next.id === params.id).pop();

          if (this.project) {
            const title = `${this.project.headline} | ${this.env.texts.title}`;
            this.title.setTitle(title);
            this.meta.updateTag({
              name: 'og:title',
              content: title,
            });
            this.meta.updateTag({
              name: 'og:description',
              content: this.project.header,
            });
          } else {
            this.router.navigate(['/']);
          }
        }
      });
    });
  }
}
