import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Service from '../model/Service';
import AbstractRestService from './abstract-rest-service.interface';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceStore extends AbstractRestService<Service> {
  constructor(http: HttpClient, env: EnvironmentService) {
    super(http, env.servicesFetchUrl);
  }
}
