export enum ContentType {
  'MUSIC' = 'music',
  'DOCUMENT' = 'document',
  'PHOTO' = 'photo',
  'BOOK' = 'book',
  'NEWSPAPER' = 'newspaper',
  'RENDER' = 'render',
  'ARTWORK' = 'artwork',
  'RITUAL' = 'ritual',
  'POSTCARD' = 'postcard',
  'INTERVIEW' = 'interview',
  'BULK' = 'bulk',
}

export interface ContentTypeDetails {
  name: string;
  description: string;
  imageUrl?: string;
  fontColor?: string;
  noIconColor?: string;
}

export const getContentTypeDetails = (
  contentType: ContentType
): ContentTypeDetails => {
  switch (contentType) {
    case ContentType.MUSIC:
      return {
        name: 'music',
        description: 'Music',
        imageUrl: '/assets/img/icons/content-types/music.svg',
        fontColor: '#0849ff',
      };

    case ContentType.DOCUMENT:
      return {
        name: 'document',
        description: 'Document',
        imageUrl: '/assets/img/icons/content-types/document.svg',
        fontColor: '#fa2209',
      };

    case ContentType.PHOTO:
      return {
        name: 'photo',
        description: 'Photograph',
        imageUrl: '/assets/img/icons/content-types/photo.svg',
        fontColor: '#fac807',
      };

    case ContentType.BOOK:
      return {
        name: 'book',
        description: 'Book',
        imageUrl: '/assets/img/icons/content-types/book.svg',
        fontColor: '#545454',
      };

    case ContentType.NEWSPAPER:
      return {
        name: 'newspaper',
        description: 'Newspaper',
        imageUrl: '/assets/img/icons/content-types/newspaper.svg',
        fontColor: '#8C9597',
      };

    case ContentType.RENDER:
      return {
        name: 'render',
        description: '3D Rendering',
        imageUrl: '/assets/img/icons/content-types/3d.svg',
        fontColor: '#BABABA',
      };

    case ContentType.ARTWORK:
      return {
        name: 'artwork',
        description: 'Artwork',
        imageUrl: '/assets/img/icons/content-types/artwork.svg',
        fontColor: '#081DC3',
      };

    case ContentType.RITUAL:
      return {
        name: 'ritual',
        description: 'Ritual Object',
        imageUrl: '/assets/img/icons/content-types/ritual.svg',
        fontColor: '#4E7CFE',
      };

    case ContentType.POSTCARD:
      return {
        name: 'postcard',
        description: 'Postcard',
        imageUrl: '/assets/img/icons/content-types/postcard.svg',
        fontColor: '#4108FF',
      };

    case ContentType.INTERVIEW:
      return {
        name: 'interview',
        description: 'Oral Interview',
        imageUrl: '/assets/img/icons/content-types/interview.svg',
        fontColor: '#6700D4',
      };

    case ContentType.BULK:
      return {
        name: 'bulk',
        description: 'Bulk Dataset',
        imageUrl: '/assets/img/icons/content-types/bulk.svg',
        fontColor: '#000',
      };

    default:
      return {
        name: '',
        description: 'Type name',
        noIconColor: '#000',
      };
  }
};
