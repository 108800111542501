<main class="about">
  <app-header></app-header>
  <section class="about__organization-info">
    <div class="component-content" [innerHTML]="aboutUs"></div>
  </section>
  <app-partners id="partners" [store]="partnerStore"></app-partners>
  <app-team></app-team>
  <!--
  <app-vacancies></app-vacancies>
 -->
  <app-footer></app-footer>
</main>