import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import Dataset from 'src/app/model/Dataset';
import Partner from 'src/app/model/Partner';
import { DatasetFilterService } from 'src/app/services/dataset-filter.service';
import { DatasetStore } from 'src/app/services/dataset.service';
import { PartnerAboutStore } from 'src/app/services/partner-about.service';
import { DatasetPreviewCardComponent } from './dataset-preview-card/dataset-preview-card.component';

@Component({
  selector: 'app-dataset-preview-list',
  templateUrl: './dataset-preview-list.component.html',
  styleUrls: ['./dataset-preview-list.component.scss'],
})
export class DatasetPreviewListComponent implements OnInit, OnDestroy {
  @ViewChild('datasetPreviewListContainer') container: ElementRef;
  @ViewChild('datasetPreviewList') list: ElementRef;
  @ViewChildren(DatasetPreviewCardComponent) cards: QueryList<
    DatasetPreviewCardComponent
  >;

  @Input() showToolbar: boolean;
  @Input() limit: number;

  datasets: Observable<Dataset[]>;

  languages: string[] = [];
  institutions: Partner[] = [];

  private datasetPointer: number = 0;

  private datasetSourceSub: Subscription;
  private datasetLanguageSub: Subscription;
  private searchSub: Subscription;
  private selectedContentTypesSub: Subscription;

  constructor(
    private datasetStore: DatasetStore,
    private partnerStore: PartnerAboutStore,
    private filterService: DatasetFilterService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.datasets = this.datasetStore.getAll().pipe(
      tap((datasets) => {
        if (this.showToolbar && datasets && datasets.length) {
          this.languages = [
            ...datasets.reduce((accum, dataset) => {
              // Languages
              dataset.languages &&
                dataset.languages.reduce((result, next) => {
                  result.add(next);
                  return result;
                }, accum);

              return accum;
            }, new Set<string>()),
          ].sort();

          // Institutions
          const partnersSub = this.partnerStore
            .getAll()
            .pipe(
              tap(
                (partners) => {
                  if (partners && partners.length) {
                    this.institutions = [
                      ...datasets.reduce((accum, dataset) => {
                        const partner = partners.find(
                          (next) => next.id === dataset.providerId
                        );
                        partner && accum.add(partner);
                        return accum;
                      }, new Set<Partner>()),
                    ].sort((a, b) =>
                      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                    );
                  }
                },
                (error) => console.log(error),
                () => {
                  partnersSub && partnersSub.unsubscribe();
                }
              )
            )
            .subscribe();
        }
      })
    );

    // Subscribing to update the url query params
    if (this.showToolbar) {
      // Dataset institution
      this.datasetSourceSub = this.filterService.datasetSource$.subscribe(
        (datasetSource) => {
          const queryParams = {
            datasetSource: datasetSource ? datasetSource : undefined,
          };
          this.router.navigate([], {
            queryParams,
            queryParamsHandling: 'merge',
          });
        }
      );

      // Dataset language
      this.datasetLanguageSub = this.filterService.datasetLanguage$.subscribe(
        (datasetLanguage) => {
          const queryParams = {
            datasetLanguage: datasetLanguage ? datasetLanguage : undefined,
          };
          this.router.navigate([], {
            queryParams,
            queryParamsHandling: 'merge',
          });
        }
      );

      // Search value
      this.searchSub = this.filterService.searchValue$.subscribe(
        (searchValue) => {
          const queryParams = { search: searchValue ? searchValue : undefined };
          this.router.navigate([], {
            queryParams,
            queryParamsHandling: 'merge',
          });
        }
      );

      // Content types multi
      this.selectedContentTypesSub = this.filterService.selectedContentTypes$.subscribe(
        ([...selectedContentTypes]) => {
          const queryParams = {
            contentType: selectedContentTypes.length
              ? selectedContentTypes
              : undefined,
          };
          this.router.navigate([], {
            queryParams,
            queryParamsHandling: 'merge',
          });
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.searchSub && this.searchSub.unsubscribe();
    this.selectedContentTypesSub && this.selectedContentTypesSub.unsubscribe();
    this.datasetSourceSub && this.datasetSourceSub.unsubscribe();
    this.datasetLanguageSub && this.datasetLanguageSub.unsubscribe();
  }

  get searchValue() {
    return this.filterService.searchValue;
  }

  navIconClickHandler(forward: boolean) {
    this.datasetPointer += forward ? 1 : -1;

    const { offsetLeft: nextCardOffset } = this.cards
      .filter(
        (_, index) =>
          index ===
          Math.abs(
            (this.cards.length + this.datasetPointer) % this.cards.length
          )
      )
      .shift().el.nativeElement;

    this.list.nativeElement.scrollTo({
      left:
        nextCardOffset -
        parseFloat(
          window.getComputedStyle(this.container.nativeElement).paddingLeft
        ),
      behavior: 'smooth',
    });
  }
}
