import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import Supporter from 'src/app/model/Supporter';
import { SupporterStore } from 'src/app/services/supporter.service';

@Component({
  selector: 'app-footer-supporters',
  templateUrl: './footer-supporters.component.html',
  styleUrls: ['./footer-supporters.component.scss'],
})
export class FooterSupportersComponent implements OnInit {
  supporters: Observable<Supporter[]>;

  constructor(private store: SupporterStore) {}

  ngOnInit(): void {
    this.supporters = this.store.getAll();
  }
}
