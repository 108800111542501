<div class="dropdown">
  <button
    (click)="toggleHandler()"
    class="dropdown__value"
    [ngClass]="{ dropdown__value_light: isOpen }"
  >
    {{
      selectedItem
        ? userKey
          ? selectedItem[userKey]
          : selectedItem
        : placeholder
    }}
  </button>
  <ul class="dropdown-menu" [ngClass]="{ 'dropdown-menu_open': isOpen }">
    <li
      *ngFor="let item of items"
      class="dropdown-menu__item"
      [ngClass]="{ selected: item === selectedItem }"
      (click)="clickSelectHandler(item)"
      [title]="
        item === selectedItem
          ? 'Select to clear'
          : userKey
          ? item[userKey]
          : item
      "
    >
      {{ userKey ? item[userKey] : item }}
    </li>
  </ul>
</div>
