import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DomEventsUtilsService } from 'src/app/services/dom-events-utils.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnDestroy {
  @Input() placeholder: string = 'Select value';
  @Input() items: any[];
  @Input() selectedItem: any;
  @Input() userKey: string;
  @Input() selectHandler: (item: string) => {};

  private clickedTargetSub: Subscription;

  public isOpen: boolean;

  constructor(
    private ref: ElementRef,
    private domEvents: DomEventsUtilsService
  ) {}

  ngOnInit(): void {
    this.clickedTargetSub = this.domEvents.clickedTarget.subscribe((target) =>
      this.outsideClickHandler(target)
    );
  }

  ngOnDestroy(): void {
    this.clickedTargetSub.unsubscribe();
  }

  toggleHandler() {
    this.isOpen = !this.isOpen;
  }

  clickSelectHandler(item: any) {
    this.selectedItem = item;
    this.selectHandler(item);
    this.toggleHandler();
  }

  outsideClickHandler(target: EventTarget) {
    if (this.isOpen && !this.ref.nativeElement.contains(target)) {
      this.toggleHandler();
    }
  }
}
