import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import Service from 'src/app/model/Service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { ServiceStore } from 'src/app/services/service.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss'],
})
export class ServiceComponent implements OnInit {
  service: Service;

  constructor(
    private route: ActivatedRoute,
    private store: ServiceStore,
    private meta: Meta,
    private title: Title,
    private env: EnvironmentService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.store.getAll().subscribe((data) => {
        this.service = data.filter((next) => next.id === params.id).pop();

        if (this.service) {
          this.title.setTitle(
            `${this.service.title} | ${this.env.texts.title}`
          );
          this.meta.updateTag({
            name: 'og:title',
            content: this.service.title,
          });
          this.meta.updateTag({
            name: 'og:description',
            content: this.service.description,
          });
          this.meta.updateTag({
            name: 'og:url',
            content: window.location.href,
          });
        }
      });
    });
  }
}
