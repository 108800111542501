import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import TeamMember from '../model/TeamMember';
import AbstractRestService from './abstract-rest-service.interface';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class TeamMemberStore extends AbstractRestService<TeamMember> {
  constructor(http: HttpClient, env: EnvironmentService) {
    super(http, env.teamMembersFetchUrl);
  }
}
