<div class="news-card">
  <a
    [href]="newsItem.url"
    target="_blank"
    rel="noopener noreferrer"
    class="news-card__link"
  >
    <div class="news-card__image">
      <img [src]="newsItem.imageUrl" [alt]="newsItem.title" />
    </div>
    <div class="news-card__title">
      <span class="news-card__source">{{ newsItem.source }}:</span>
      {{ newsItem.title }}
      <app-button preset="transparent-yellow"></app-button>
    </div>
  </a>
</div>
