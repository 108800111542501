<div class="dataset-preview-toolbar">
  <div class="dataset-preview-toolbar__row margin-bottom">
    <app-dropdown
      id="toolbar-source-dropdown"
      placeholder="Source or Institution Name"
      [items]="datasetSourcesItems"
      [selectedItem]="selectedDatasetSource"
      userKey="name"
      [selectHandler]="datasetSourceSelectHandler.bind(this)"
    ></app-dropdown>
    <app-dropdown
      id="toolbar-language-dropdown"
      placeholder="Language"
      [items]="languageItems"
      [selectedItem]="selectedDatasetLanguage | async"
      [selectHandler]="languageSelectHandler.bind(this)"
    ></app-dropdown>
    <app-input
      id="search-input-desktop"
      placeholder="Search by any definition"
      [value]="search | async"
      (onChange)="searchInputChangeHandler($event)"
    ></app-input>
  </div>
  <div
    class="dataset-preview-toolbar__row"
    *ngIf="selectedContentTypes | async as selected"
  >
    <app-content-type-badge
      [ngClass]="{ selected: selected.has(contentType) }"
      *ngFor="let contentType of contentTypes"
      [contentType]="contentType"
      (onClick)="contentTypeClickHandler($event)"
    >
    </app-content-type-badge>
  </div>
  <div
    class="dataset-preview-toolbar__row dataset-preview-toolbar__row_mobile margin-bottom"
  >
    <app-input
      id="search-input-mobile"
      placeholder="Search by any definition"
      [value]="search | async"
      (onChange)="searchInputChangeHandler($event)"
    ></app-input>
  </div>
  <div class="dataset-preview-toolbar__row">
    <app-button
      [ngStyle]="{ visibility: filterEnabled ? 'visible' : 'hidden' }"
      preset="transparent-blue"
      (onClick)="resetFilterClickHandler()"
      >Reset filters</app-button
    >
  </div>
</div>
