import { Component } from '@angular/core';
import { EnvironmentService } from 'src/app/services/environment.service';
import { PartnerAboutStore } from 'src/app/services/partner-about.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent {
  constructor(
    public partnerStore: PartnerAboutStore,
    private env: EnvironmentService
  ) {}

  get aboutUs(): string {
    return this.env.texts.aboutUs;
  }
}
