import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import TeamMember from 'src/app/model/TeamMember';
import { TeamMemberStore } from 'src/app/services/team-member.service';

@Component({
  selector: 'app-team-members-list',
  templateUrl: './team-members-list.component.html',
  styleUrls: ['./team-members-list.component.scss'],
})
export class TeamMembersListComponent implements OnInit {
  teamMembers: Observable<TeamMember[]>;

  constructor(private store: TeamMemberStore) {}

  ngOnInit(): void {
    this.teamMembers = this.store.getAll();
  }
}
