import { Pipe, PipeTransform } from '@angular/core';
import Dataset from '../model/Dataset';
import { DatasetFilterService } from '../services/dataset-filter.service';
import { forkJoin } from 'rxjs';

@Pipe({
  name: 'datasetFilter',
})
export class DatasetFilterPipe implements PipeTransform {
  constructor(private filterService: DatasetFilterService) {}

  transform(datasets: Dataset[]): Dataset[] {
    const {
      datasetSource$: { value: datasetSource },
      datasetLanguage$: { value: datasetLanguage },
      searchValue$: { value: searchValue },
      selectedContentTypes$: { value: selectedContentTypes },
    } = this.filterService;

    if (!datasets.length) {
      return datasets;
    }

    return datasets.filter(
      (dataset) =>
        // Free search
        (!searchValue ||
          (dataset.index &&
            dataset.index.includes(searchValue.toLowerCase().trim()))) &&
        // Language search
        (!datasetLanguage ||
          (dataset.index &&
            dataset.index.includes(datasetLanguage.toLowerCase().trim()))) &&
        // Search by provider
        (!datasetSource ||
          (dataset.providerId && dataset.providerId === datasetSource)) &&
        // Search by content types
        (!selectedContentTypes.size ||
          (dataset.contentTypes &&
            dataset.contentTypes.filter((type) =>
              selectedContentTypes.has(type)
            ).length))
    );

    return datasets;
  }
}
