<section class="component-content">
  <!--
  <h2>
    We are always looking for bright people to join us.<span><a href="mailto:info@jhn.ngo">Write to us</a></span>
  </h2>
  -->
  <h2>Board of Directors</h2>
  <!-- TODO ngif restore vacancies -->
  <div class="vacancies" *ngIf="(vacancies | async) as vacancies">
    <div *ngFor="let vacancy of vacancies" class="vacancies__item">
      <div>
        <img src="{{ vacancy.image }}" />
        <h4>{{ vacancy.name }}</h4>
        <p>{{ vacancy.description }}</p>
      </div>

      <div *ngIf="false">
        <a [href]="vacancy.link" target="_blank" rel="noopener noreferrer">
          <img src="assets/img/icons/arrow-yellow.svg" alt="" />
          <span>Apply Now</span>
        </a>
      </div>
    </div>
  </div>
</section>