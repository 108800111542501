import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() preset: 'blue' | 'white' = 'white';
  @Input() link: string = '';
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  get isAbsoluteLink(): boolean {
    return (
      this.link &&
      (this.link.indexOf('://') > 0 || this.link.indexOf('//') === 0)
    );
  }
}
