import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Partner from '../model/Partner';
import AbstractRestService from './abstract-rest-service.interface';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class PartnerStore extends AbstractRestService<Partner> {
  constructor(http: HttpClient, env: EnvironmentService) {
    super(http, env.partnersFetchUrl);
  }
}
