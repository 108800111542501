import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import Dataset from 'src/app/model/Dataset';
import Partner from 'src/app/model/Partner';
import { DatasetStore } from 'src/app/services/dataset.service';
import { EnvironmentService } from 'src/app/services/environment.service';
import { PartnerAboutStore } from 'src/app/services/partner-about.service';

@Component({
  selector: 'app-dataset',
  templateUrl: './dataset.component.html',
  styleUrls: ['./dataset.component.scss'],
})
export class DatasetComponent implements OnInit {
  dataset: Dataset;
  provider: Partner;

  constructor(
    private route: ActivatedRoute,
    private datasetStore: DatasetStore,
    private partnerStore: PartnerAboutStore,
    private location: Location,
    private env: EnvironmentService,
    private title: Title,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.datasetStore.getAll().subscribe((data) => {
        this.dataset = data.filter((next) => next.id === params.id).pop();

        if (this.dataset) {
          this.title.setTitle(`${this.dataset.name} | ${this.env.texts.title}`);
          this.meta.updateTag({ name: 'og:title', content: this.dataset.name });
          this.meta.updateTag({
            name: 'og:description',
            content: this.dataset.tags && this.dataset.tags.join(', '),
          });
          this.meta.updateTag({
            name: 'og:url',
            content: window.location.href,
          });

          this.partnerStore.getAll().subscribe((data) => {
            this.provider = data
              .filter((next) => next.id === this.dataset.providerId)
              .pop();
          });
        }
      });
    });
  }

  goBackHandler() {
    this.location.back();
  }

  encodeURIComponent(uri: string) {
    return encodeURIComponent(uri);
  }
}
