import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import Vacancy from 'src/app/model/Vacancy';
import { VacancyStore } from 'src/app/services/vacancy.service';

@Component({
  selector: 'app-vacancies',
  templateUrl: './vacancies.component.html',
  styleUrls: ['./vacancies.component.scss'],
})
export class VacanciesComponent implements OnInit {
  vacancies: Observable<Vacancy[]>;

  constructor(private store: VacancyStore) {}

  ngOnInit(): void {
    this.vacancies = this.store.getAll();
  }
}
