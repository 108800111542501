<header class="header" role="banner">
  <div class="component-content">
    <app-navbar></app-navbar>
    <h1
      class="header__title"
      *ngIf="headerTitle"
      [innerHTML]="headerTitle"
    ></h1>
    <div class="header__image"></div>
  </div>
</header>
