import { Component, OnInit, Input } from '@angular/core';
import {
  ContentType,
  ContentTypeDetails,
  getContentTypeDetails,
} from 'src/app/model/ContentType';
import { DatasetFilterService } from 'src/app/services/dataset-filter.service';
import { Observable } from 'rxjs';
import Partner from 'src/app/model/Partner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-dataset-preview-toolbar',
  templateUrl: './dataset-preview-toolbar.component.html',
  styleUrls: ['./dataset-preview-toolbar.component.scss'],
})
export class DatasetPreviewToolbarComponent {
  @Input() languageItems: string[];
  @Input() datasetSourcesItems: Partner[];

  constructor(private filterService: DatasetFilterService) {}

  get selectedDatasetLanguage(): Observable<string> {
    return this.filterService.datasetLanguage;
  }

  get selectedDatasetSource(): Partner {
    return this.datasetSourcesItems.find(
      (next) => next.id === this.filterService.datasetSource$.value
    );
  }

  get search(): Observable<string> {
    return this.filterService.searchValue;
  }

  get selectedContentTypes(): Observable<Set<ContentType | string>> {
    return this.filterService.selectedContentTypes;
  }

  get filterEnabled(): boolean {
    return this.filterService.filterEnabled;
  }

  resetFilterClickHandler(): void {
    this.filterService.reset();
  }

  languageSelectHandler(language: string): void {
    const { value: datasetLanguage } = this.filterService.datasetLanguage$;

    this.filterService.datasetLanguage$.next(
      datasetLanguage !== language ? language : ''
    );
  }

  datasetSourceSelectHandler(datasetSource: Partner): void {
    const { value: source } = this.filterService.datasetSource$;

    this.filterService.datasetSource$.next(
      source !== datasetSource.id ? datasetSource.id : ''
    );
  }

  contentTypeClickHandler(contentType: ContentType): void {
    const {
      value: selectedContentTypes,
    } = this.filterService.selectedContentTypes$;
    if (selectedContentTypes.has(contentType)) {
      selectedContentTypes.delete(contentType);
    } else {
      selectedContentTypes.add(contentType);
    }
    this.filterService.selectedContentTypes$.next(selectedContentTypes);
  }

  searchInputChangeHandler(searchValue: string): void {
    this.filterService.searchValue$.next(searchValue);
  }

  get contentTypes() {
    return [...Object.values(ContentType)];
  }
}
