import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DomEventsUtilsService } from 'src/app/services/dom-events-utils.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  animations: [
    trigger('menuToggle', [
      state('open', style({ right: '0', 'z-index': 11 })),
      state('closed', style({ right: '-70.278vw' })),
      transition('* <=> *', [animate('0.25s')]),
    ]),
    trigger('tintToggle', [
      state(
        'open',
        style({ 'background-color': 'rgba(0, 0, 0, 0.75)', 'z-index': 10 })
      ),
      state(
        'closed',
        style({ 'background-color': 'rgba(0, 0, 0, 0)', 'z-index': -1 })
      ),
      transition('* <=> *', [animate('0.25s')]),
    ]),
  ],
})
export class MobileMenuComponent {
  isOpen: boolean = false;
  @ViewChild('menu') menuRef: ElementRef;
  private clickedTargetSub: Subscription;

  constructor(
    private router: Router,
    private domEvents: DomEventsUtilsService
  ) {
    const navEndEvents = router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    navEndEvents.subscribe(() => {
      if (this.isOpen) {
        this.toggleMenuHandler();
      }
    });
  }

  toggleMenuHandler(): void {
    this.isOpen = !this.isOpen;
  }

  async menuItemClickHandler(anchor: string, route: string = undefined) {
    this.toggleMenuHandler();

    if (route) {
      await this.router.navigate([route]);
    }

    setTimeout(() => {
      document
        .querySelector(`#${anchor}`)
        .scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }

  animationDone() {
    if (this.isOpen) {
      this.clickedTargetSub = this.domEvents.clickedTarget.subscribe((target) =>
        this.outsideClickHandler(target)
      );
    } else {
      this.clickedTargetSub && this.clickedTargetSub.unsubscribe();
    }
  }

  outsideClickHandler(target: EventTarget) {
    if (this.isOpen && !this.menuRef.nativeElement.contains(target)) {
      this.toggleMenuHandler();
    }
  }
}
