import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  constructor(private router: Router) {}

  async navItemClickHandler(anchor: string, route: string = undefined) {
    if (route) {
      await this.router.navigate([route]);
    }

    setTimeout(() => {
      // TODO refactor to be Angular-like
      document
        .querySelector(`#${anchor}`)
        .scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }
}
