<app-header [headerTitle]="project?.headline"></app-header>
<div class="component-content project">
  <h2 class="project__header" [innerHTML]="project?.header"></h2>
  <p class="project__paragraph" *ngFor="let p of project?.paragraphs">
    <span [innerHTML]="p.header"></span>
    <span [innerHTML]="p.content"></span>
  </p>
  <div class="get-in-touch">
    <div class="get-in-touch__title">Are you missing anything?</div>
    <app-button preset="blue"
      ><a href="mailto:info@jhn.ngo">Get in touch with us</a></app-button
    >
  </div>
</div>
<app-footer></app-footer>
